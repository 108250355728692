import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Privacy statement",
    id: "Privacy statement",
    moduleComponentName: "SectionContent",
    columnProps: {
      xs: 12,
      className: "mb-3",
    },
    rowProps: { noGutters: true },
    moduleProps: {
      elementId: "privacy-statement",
    },
    wrapWithContainer: true,
  },
];

const PrivacyStatement = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default PrivacyStatement;
